import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BodyDrawer from '../components/body-drawer';
import PostPreview from '../components/post-preview';

const Categories = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <BodyDrawer>
        {posts.map(({ node }) => {
          return <PostPreview key={node.id} data={node} />;
        })}
      </BodyDrawer>
    </Layout>
  );
};

export default Categories;

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      ...markdownRemarkEdge
    }
  }
`;
